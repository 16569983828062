.pricing-wrapper {
  background-color: #000;
  padding: 50px 0;
}

.pricing-section {
  background-size: cover;
}

.pricing-title {
  color: #fff;
  text-align: left;
  margin-left: 50px;
  margin-bottom: 30px;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  margin-bottom: 30px; /* Añadir un margen inferior general */
}

.card-header {
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-header img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bg-highlight {
  background-color: #f8f9fa;
}

.bg-dark {
  background-color: #0C1729;
}

.text-white {
  color: #fff;
}

.text-dark {
  color: #343a40;
}

.bg-transparent-dark {
  background-color: #0C1729;
}

.bg-transparent-light {
  background-color: rgba(255, 255, 255, 0.7); /* Blanco transparente */
}

.badge.bg-green {
  background-color: #00A7F7;
  color: #0C1729;
}

.badge.bg-black {
  background-color: #0C1729;
}

.card .btn-outline-light {
  color: #fff;
  border-color: #fff;
}

.card .btn-outline-dark {
  color: #000;
  border-color: #000;
}

.card .btn-outline-light:hover, .card .btn-outline-light:focus {
  background-color: #00A7F7;
  color: #000;
}

.card .btn-outline-dark:hover, .card .btn-outline-dark:focus {
  background-color: #000;
  color: #00A7F7;
}

.text-muted-dark {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-muted-light {
  color: rgba(0, 0, 0, 0.6) !important;
}

.check-circle {
  color: #00A7F7;
}

.title-image {
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin-left: 10px;
}

.card-bottom-image {
  height: 70px;
  margin: 15px 0;
  object-fit: contain;
}

.card-bottom-placeholder {
  height: 70px;
  margin: 15px 0;
}

.small-image {
  width: 70px;
  height: 70px;
}

/* Espacio vertical adicional para móviles */
@media (max-width: 768px) {
  .card {
    padding: 30px;
  }

  .bg {
    padding: 50px;
  }
}
