.trial-class-section-unique {
  background-image: url('../images/pattern.jpg'); /* Fondo de patrón */
  background-size: cover;
  background-position: center;
  color: #000; /* Texto negro */
  padding: 50px 0;
  position: relative;
  overflow: hidden; /* Para asegurar que la imagen de fondo no se salga */
}

.trial-class-section-unique .container {
  position: relative;
  z-index: 2; /* Ajustado para estar encima de la imagen de fondo */
}

.trial-class-section-unique::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Debajo del contenido del contenedor */
}

.trial-class-section-unique .background-image-unique {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  z-index: 0;
  max-height: 100%; /* Asegura que la imagen no se salga de la sección */
  object-fit: cover;
}

.trial-class-section-unique img {
  max-width: 100%;
  height: auto;
}

.trial-class-section-unique .text-body-emphasis {
  color: #000; /* Texto negro */
}

.trial-class-section-unique .lead {
  color: #000; /* Texto negro */
}

.trial-button-unique {
  background-color: #000; /* Botón negro */
  border-color: #000;
}

.trial-button-unique:hover,
.trial-button-unique:focus {
  background-color: #00A7F7; /* Hover verde */
  border-color: #00A7F7;
  color: #000;
}
