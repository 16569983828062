.parallax {
  background-image: url('../images/parallax-image.jpg');
  height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.parallax-content {
  padding: 20px;
  border-radius: 8px;
}

.parallax-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

