.ads-wrapper {
    background-color: #000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 40px;
  }

  .ads-content {
    background-color: #000;
  }

  .ads-content {
    background-color: #000; /* Color de fondo negro */
    color: #fff; /* Color de texto blanco para contraste */
    padding: 20px;
    border-radius: 8px;
  }
  
  .ads-content h2 {
    color: #00A7F7;
    margin-bottom: 15px;
  }
  
  .ads-content p {
    color: #fff;
    margin-bottom: 20px;
  }
  
  .ads-content .btn-primary {
    background-color: #00A7F7;
    border-color: #000;
    color:#000;
  }
  
  .ads-content .btn-primary:hover,
  .ads-content .btn-primary:focus {
    background-color: #000;
    border-color: #00A7F7;
    color: #00A7F7;
  }
  
  







  .ads-wrapper {
    padding: 20px;
    margin: 20px 0;
    text-align: center;
  }
  
  .ads-content {
    background-color: #000; /* Color de fondo negro */
    color: #fff; /* Color de texto blanco para contraste */
    padding: 20px;
    border-radius: 8px;
  }
  
  .ads-content h2,
  .ads-content p {
    margin: 10px 0;
  }
  
  .ads-content .btn {
    margin-top: 10px;
  }
  
  