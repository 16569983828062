.store-wrapper {
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body h6 {
  color: #004c00;
}

.card {
  border-radius: 0.5rem;
}

.card-img-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 200px; /* Altura fija para todas las imágenes */
  object-fit: cover; /* Recorta la imagen para que encaje en el contenedor sin distorsionarse */
}

.card-title {
  font-size: 1.50rem;
  font-weight: bold;
}

.card-text {
  font-size: 0.75rem;
}

.card .btn-primary {
  margin-left: 2%;
}

.btn-primary {
  background-color: #00A7F7;
  border: none;
  color: #000;
}

.btn-primary:hover {
  background-color: #6c757d;
}

.btn-secondary {
  color: #6c757d;
  background-color: #fff;
  border: none;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.ads-wrapper {
  margin-top: 50px;
  text-align: center;
}

.ads-content {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ads-content h2 {
  margin-bottom: 15px;
}

.ads-content p {
  margin-bottom: 20px;
}

.ads-content .btn-primary {
  background-color: #00A7F7;
  border-color: #00A7F7;
}

.ads-content .btn-primary:hover,
.ads-content .btn-primary:focus {
  background-color: #0056b3;
  border-color: #004085;
}

.store-title {
  text-align: left;
}

.card-body ul {
  padding-left: 0;
  list-style: none;
}

.card-body ul li {
  padding-left: 1.5em;
  position: relative;
}

.card-body ul li::before {
  content: none;
}

.card-body ul.list-custom li i {
  color: #00A7F7;
}

.text-success {
  color: #004c00 !important;
}

.card-text.descripcion {
  font-size: 0.9rem;
  font-weight: 300;
}
