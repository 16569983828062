@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.navbar-brand {
  font-weight: bold;
}

.brand-highlight {
  color: #00A7F7; 
}

.navbar-nav .nav-link {
  color: #FFFFFF;
  margin-right: 1rem;
}

.navbar-nav .nav-link.active {
  color: #00A7F7;
}

.navbar-nav .nav-link:hover {
  color: #00A7F7;
}

.container {
  max-width: 1140px;
}

.cart-icon-wrapper {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #00A7F7;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  margin-right: 0.5rem;
}

.cart-icon-wrapper .fas {
  color: #000000;
  font-size: 16px;
}

/*Footer*/
.custom-footer {
  background-color: #000; /* Fondo negro */
  color: #fff; /* Texto blanco */
}

.custom-footer .text-white {
  color: #fff !important; /* Texto blanco */
}

.custom-footer .btn-social {
  margin: 0 10px;
  padding: 10px;
}

.custom-footer .btn-social svg.icon-white path {
  fill: #fff !important; /* Asegura que los iconos sean del color del texto */
}

.custom-footer .btn-social:hover {
  color: #00A7F7 !important; /* Hover en verde */
}

.custom-footer .btn-social:hover svg.icon-white path {
  fill: #00A7F7 !important; /* Iconos en verde al hacer hover */
}
