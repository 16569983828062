/* Servicios CSS */

.services-section .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border: none;
}

.services-section .btn-secondary:hover {
  background-color: #5a6268;
}

.services-section .btn-primary {
  background-color: #00A7F7;
  border: none;
  color: #000;
}

.services-section .btn-primary:hover {
  background-color: #004c00;
}

.service-col .service-image {
  /* Estilos específicos para la imagen en la sección de servicios */
}

.highlight-service {
  /* Estilos para resaltar servicios */
}

.section-title {
  text-align: left;
}

.service-col .service-icon {
  /* Estilos específicos para el icono */
}






.services-wrapper {
  background: url('../images/pattern.jpg') no-repeat center center;
  background-size: cover;
  padding: 50px 0 30px 0; /* Incrementar padding arriba, reducir padding abajo */
}

.container.marketing.services-section {
  position: relative;
  min-height: 600px; /* Asegura una altura mínima */
  overflow: hidden; /* Asegura que el pseudo-elemento no desborde */
}

.section-title {
  text-align: left;
  margin-bottom: 50px; /* Margen inferior para separación del contenido */
  color: #000; /* Color del título */
}

.service-col {
  text-align: center;
  margin-bottom: 20px; /* Reducir el margen inferior para mayor espacio vertical */
}

.service-col.highlight-service {
  background-color: #f8f9fa;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añadir sombra para resaltar más */
}

.service-image {
  width: 140px;
  height: 140px;
  object-fit: cover;
  margin-bottom: 20px;
}

.service-icon {
  font-size: 40px; /* Increase font size to increase icon size */
  margin-top: 20px;
  color: #6c757d; /* Color gris for the icons */
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #5a6268;
  border-color: #545b62;
}

.service-image-modal {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}