.aboutme-section {
  background-color: #ffffff;
  padding-top: 50px;
  position: relative;
  margin: 0;
}

.featurette-heading {
  font-size: 2.0rem;
  font-weight: bold;
}

.text-highlight {
  color: #00A7F7;
}

.text-gray {
  color: gray;
}

.lead {
  font-size: 0.9rem; /* Reduced font size */
  text-align: justify;
}

.aboutme-image-container {
  height: 0%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: auto; /* Added margin-top: auto */
}

.aboutme-image {
  width: 100%;
  object-fit: cover;
  margin: 0;
  cursor: pointer; /* Show that the image is clickable */
}

.featurette-divider {
  margin: 5rem 0;
}

.featurette-text {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 768px) {
  .aboutme-image-container {
    height: auto;
    margin-top: 20px;
    align-items: center;
    padding-right: 0;
  }

  .aboutme-image {
    height: auto;
    object-fit: contain;
    max-width: 100%;
  }

  .featurette-text {
    padding-left: 15px;
    padding-right: 15px;
  }
}
