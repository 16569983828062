/* Importa las tipografías necesarias desde Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&family=Montserrat:wght@400;700&family=Poppins:wght@400;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0; /* Asegúrate de que no haya margen en el body */
  background-color: #000; /* Fondo negro para todo el body */
}

.presentation-container {
  background-image: url('../images/backgroundPresentation.webp'); /* Imagen de fondo */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #FFFFFF; /* Texto blanco */
  padding: 50px 20px;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 100vh; /* Asegura que el fondo ocupe toda la altura de la vista */
  width: 100vw; /* Asegura que el fondo ocupe todo el ancho de la ventana */
  overflow: hidden; /* Evita que los elementos salgan del contenedor */
}

.presentation-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Asegúrate de que el pseudo-element esté detrás de otros contenidos */
}

.presentation-container > .row {
  position: relative;
  z-index: 1; /* Asegura que el contenido esté por encima del pseudo-element */
  width: 100%; /* Asegura que la fila ocupe todo el ancho del contenedor */
}

.studio-name {
  font-family: 'League Spartan', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.brand-highlight {
  color: #00A7F7; /* Color verde */
}

.jiu-jitsu-title {
  font-family: 'Impact', sans-serif;
  font-size: 6rem; /* Ajustar el tamaño de la fuente */
  margin-bottom: 0;
  line-height: 1;
}

.brazilian {
  font-size: 3rem; /* Ajustar el tamaño de la fuente para que coincida */
  display: block;
}

.subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.cta-button {
  font-family: 'Poppins', sans-serif;
  background-color: #000000; 
  color: #FFFFFF; 
  border: 2px solid #FFFFFF; 
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 5px; 
  transition: all 0.3s ease; /* Transición suave para el hover */
  z-index: 2; /* Asegúrate de que el botón esté por encima del pseudo-element */
  position: relative;
}

.cta-button:hover {
  background-color: #00A7F7; 
  color: #000000;
  border: 2px solid #00A7F7; 
}

.affiliations {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.affiliation-logo {
  width: 80px;
  height: auto;
  margin-left: 10px;
  flex-shrink: 0; /* Evitar que los logos se redimensionen */
}

.jiu-jitsu-image {
  width: 150%; /* Ajustar según el contenedor */
  display: flex;
  justify-content: flex-end; /* Cargar a la derecha */
}

.jiu-jitsu-image img {
  max-width: 100%;
  height: auto;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .studio-name {
    font-size: 1.5rem;
  }

  .presentation-container {
    text-align: center;
  }

  .jiu-jitsu-image {
    position: static;
    justify-content: center; /* Cargar al centro en dispositivos móviles */
  }

  .jiu-jitsu-title {
    font-size: 3rem; /* Ajustar el tamaño de la fuente para dispositivos móviles */
  }

  .brazilian {
    font-size: 1.5rem; /* Ajustar el tamaño de la fuente para dispositivos móviles */
  }

  .subtitle {
    font-size: 1rem;
  }
}
