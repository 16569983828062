.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 24px;
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.service-image-modal {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.modal-body {
  font-size: 16px;
}

.modal-body h4 {
  margin-top: 20px;
  font-size: 18px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  margin-top: 20px;
}

.btn-primary {
  padding: 10px 20px;
  background-color: #00A7F7;
  border: none;
  color: white;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #004c00;
}

.btn-secondary {
  padding: 10px 20px;
  background-color: #6c757d;
  border: none;
  color: white;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #5a6268;
}
