.parallax-background {
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: -1;
  }
  
  #myCarousel {
    background-color: transparent; 
    position: relative;
    z-index: 1;
  }
  
  .testimonial-item {
    height: 60vh; /* Ajusta la altura según lo necesario */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .testimonial-content {
    background-color: rgba(0, 0, 0, 0.6); /* Fondo semi-transparente para el contenido */
    padding: 20px;
    border-radius: 10px;
    color: #fff;
  }
  
  .testimonial-content h1 {
    color: #00A7F7;
  }

  .testimonial-content p strong {
    color: #00A7F7;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(100%);
  }

  .carousel-indicators{
    bottom: 20px;
  }
  
  .carousel-indicators button {
    background-color: #fff;
  }
  
  .carousel-indicators .active {
    background-color: #00A7F7; /* Color verde para el indicador activo */
  }
  